import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 0.7rem 1rem;
  background: #313fb7;
  border: 1px solid #fff;
  border: none;
  border-radius: 8px;
  font-family: inherit;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.03em;
  line-height: 24px;
  color: white;
  cursor: pointer;
  -webkit-box-shadow: -1px 0px 5px 0px var(--shadow);
  -moz-box-shadow: -1px 0px 5px 0px var(--shadow);
  box-shadow: -1px 0px 5px 0px var(--shadow);

  .namcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 0.5rem;
    }
  }

  &:hover:enabled {
    filter: brightness(90%);
  }

  &:disabled {
    color: #5e6c84;
    border: 1px solid #c1c7d0;
  }
`;

export default StyledButton;
