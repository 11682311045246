const aboutMe = [
  { name: 'currentLocation', details: 'Ibadan, Nigeria' },
  {
    name: 'currentJob',
    details: [
      {
        main: 'Software Engineer',
        link: 'Outlier',
        href: 'http://outlier.org',
      },
    ],
  },
  { name: 'currentJobLocation', details: 'Remote' },
  {
    name: 'previousJobs',
    details: [
      {
        main: 'Technical Support Engineer',
        link: 'Microverse',
        href: 'http://microverse.org',
      },
      {
        main: 'Software Engineer',
        link: 'Ylurn',
        href: 'http://ylurn.com',
      },
    ],
  },
  {
    name: 'education',
    details: [
      'B.Tech Electronics and Electrical Engineering',
      'Remote Full-Stack bootcamp',
    ],
  },
  {
    name: 'languages',
    details: ['HTML5', 'CSS3', 'JavaScript (ES6+)', 'TypeScript', 'Ruby'],
  },
  {
    name: 'frameworks',
    details: ['React', 'Bootstrap4', 'GatsbyJS', 'Ruby on Rails'],
  },
  {
    name: 'otherTools',
    details: [
      'SASS/SCSS/LESS',
      'RSpec',
      'Jest',
      'webpack',
      'react-bootstrap',
      'react-rails',
      'NPM',
      'yarn',
      'Git',
      'Heroku',
      'Redux',
      'Figma',
    ],
  },
  {
    name: 'interests',
    details: ['Movies', 'Swimming'],
  },
  {
    name: 'plans',
    details: [
      'Looking for a remote job opportunity where he can grow his skills',
      'Relocation jobs where he can grow his skills and meet people with more diverse backgrounds',
    ],
  },
  {
    name: 'contact',
    details: [
      {
        link: 'Contact Form',
        href: '/contact',
      },
      {
        link: 'LinkedIn',
        href: 'https://www.linkedin.com/in/saheed-oladele/',
      },
      {
        link: 'Twitter',
        href: 'https://twitter.com/suretrustng',
      },
    ],
  },
];

export default aboutMe;
