import React from 'react';
import PropTypes from 'prop-types';
import PortfolioLanguagesContainer from '../elements/PortfolioLanguagesContainer';

const PortfolioLanguages = ({ languages }) => {
  return (
    <PortfolioLanguagesContainer>
      {languages.map(lang => (
        <span key={lang}>{lang}</span>
      ))}
    </PortfolioLanguagesContainer>
  );
};

PortfolioLanguages.propTypes = {
  languages: PropTypes.array.isRequired,
};

export default PortfolioLanguages;
