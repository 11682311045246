import React from 'react';
import PropTypes from 'prop-types';
import ArticleSection from '../elements/ArticleSection';

const Article = ({ article }) => {
  const { image, href, title, description, date } = article;
  return (
    <ArticleSection>
      <div className="article-box shadow">
        <img src={image} alt={title} />
        <div className="article-content">
          <a
            className="main-link"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>{title}</h3>
          </a>
          <p>
            {description.substring(0, 250)}&hellip;
            <a href={href} target="_blank" rel="noopener noreferrer">
              read more
            </a>
          </p>
          <small>{date}</small>
        </div>
      </div>
    </ArticleSection>
  );
};

Article.propTypes = {
  article: PropTypes.object.isRequired,
};

export default Article;
