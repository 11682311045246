import React from 'react';
import '../assets/styles/Articles.scss';
import H2 from '../elements/H2';
import Article from '../components/Article';
import ARTICLES from '../constants/ARTICLES';

const Articles = () => {
  return (
    <>
      <H2 className="header2">My Articles</H2>
      <section className="articles">
        <div className="articles-container">
          {ARTICLES.map((article, index) => (
            <section className="grid-row" key={`key${index}1`}>
              {article.map(a => (
                <Article key={a.title} article={a} />
              ))}
            </section>
          ))}
        </div>
      </section>
    </>
  );
};

export default Articles;
