import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../assets/images/logo512.png';
import DarkMode from '../assets/images/dark-mode.png';
import LightMode from '../assets/images/light-mode.png';
import '../assets/styles/NavBar.scss';
import NavLinks from './NavLinks';
import { Link } from 'react-router-dom';
import NavContainer from '../elements/NavContainer';

const NavBar = ({ mode, setTheme }) => {
  return (
    <NavContainer>
      <nav>
        <Link to="/" className="logo">
          <img src={Logo} alt="logo" />
        </Link>
        <div className="nav-items">
          <NavLinks mode={mode} />
          <div className="mode">
            {mode === 'dark' ? (
              <img
                onClick={() => setTheme({ mode: 'light' })}
                src={LightMode}
                alt="light mode"
              />
            ) : (
              <img
                onClick={() => setTheme({ mode: 'dark' })}
                src={DarkMode}
                alt="dark mode"
              />
            )}
          </div>
        </div>
      </nav>
    </NavContainer>
  );
};

NavBar.propTypes = {
  setTheme: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};

export default NavBar;
