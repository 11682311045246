import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Articles from './pages/Articles';
import NotFound from './pages/NotFound';
import Layout from './components/Layout';
import Copyright from './components/Copyright';

const App = () => (
  <>
    <Layout>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/articles" exact component={Articles} />
        <Route path="/not-found" component={NotFound} />
        <Redirect to="/not-found" />
      </Switch>
    </Layout>
    <Copyright />
  </>
);

export default App;
