import styled from 'styled-components';

const FormContainer = styled.div`
  form {
    color: ${props => (props.theme.mode === 'dark' ? '#919cff' : '#313FB7')};
    background-color: ${props =>
      props.theme.mode === 'dark' ? '#171b27' : 'white'};
    font-size: 15px;

    .field {
      margin: 1rem 0;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea {
      box-sizing: border-box;
      background-color: ${props =>
        props.theme.mode === 'dark'
          ? 'var(--lang-color-dark)'
          : 'var(--lightest-purple)'};
      color: ${props =>
        props.theme.mode === 'dark'
          ? 'var(--lightest-purple)'
          : 'var(--lang-color-dark)'};
      width: 100%;
      padding: 0.5rem;
      font-size: 1em;
      font-family: var(--main-font);
      border-radius: 10px;
      outline: none;
      border: var(--lightest-purple);
      margin: 0;
      vertical-align: bottom;

      &:active,
      &:focus {
        box-sizing: border-box;
        background-color: ${props =>
          props.theme.mode === 'dark'
            ? 'var(--lang-color-dark)'
            : 'var(--lightest-purple)'};
        color: ${props =>
          props.theme.mode === 'dark'
            ? 'var(--lightest-purple)'
            : 'var(--lang-color-dark)'};
      }

      &::after {
        margin: 0;
        padding: 0;
      }
    }

    .error {
      color: red;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }
  }
`;

export default FormContainer;
