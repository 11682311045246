import React from 'react';
import '../assets/styles/Copyright.scss';

const Copyright = () => {
  const thisYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <small className="year">
      Saheed Oladele &copy;
      {thisYear()}
    </small>
  );
};

export default Copyright;
