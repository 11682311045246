import styled from 'styled-components';

const StyledLink = styled.div`
  color: ${props =>
    props.theme.mode === 'dark' ? 'rgb(156, 156, 156)' : 'rgb(93, 93, 93)'};
  a {
    outline: none;
  }
`;

export default StyledLink;
