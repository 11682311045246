import styled from 'styled-components';

const PopupContainer = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;

  .popup-inner {
    position: absolute;
    overflow-y: scroll;
    width: 80%;
    max-width: 1200px;
    height: 80%;
    left: 5%;
    right: 5%;
    top: 5%;
    bottom: 5%;
    margin: auto;
    border-radius: 16px;
    padding: 0 1rem 1rem 1rem;
    background-color: ${props =>
      props.theme.mode === 'dark' ? `#0e111b` : 'rgb(250, 250, 250)'};
    -webkit-box-shadow: -1px 0 5px 0 var(--dark-gray);
    -moz-box-shadow: -1px 0 5px 0 var(--dark-gray);
    box-shadow: -1px 0 5px 0 var(--dark-gray);

    .popup-header {
      display: flex;
      padding: 1rem 0;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: ${props =>
        props.theme.mode === 'dark' ? `#0e111b` : 'rgb(250, 250, 250)'};
      

      .close-icon {
        font-size: 30px;
        cursor: pointer;
      }

      h2 {
        margin: 0;
        padding-top: 0;
        line-height: 30px;
        color: ${props =>
          props.theme.mode === 'dark'
            ? `var(--light-purple)`
            : 'var(--deep-purple)'};
      }

      .dev-yr {
        color: var(--dark-gray);
        font-size: 12px;
        font-weight: 600;
      }
    }

    .popup-project-details {
      display: flex;
      flex-direction: column;  

      .img img {
        max-width: 100%;
        border-radius: 10px;
      }

      .project-more {
        display: flex;
        justify-content: center;

        .lang-btn {
            min-width: 400px;

            @media only screen and (max-width: 1120px) {
                min-width: 100%;
            }
        }

        p {
            display: flex;
            justify-content: center;
            align-items: center;
            @media only screen and (min-width: 1120px) {
                padding-right: 15px;
            }
        }

        hr {
            height: 1px;
            margin: 1rem 0;
            border: none;
            color: var(--hr-line-color);
            background-color: var(--hr-line-color);
        }

        button {
            margin: 1rem 1rem 1rem 0;
        }

        @media only screen and (max-width: 1120px) {
          flex-flow: row wrap;
        }
      }
    /* } */
  }
`;

export default PopupContainer;
