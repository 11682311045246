import styled from 'styled-components';

const PortfolioSection = styled.div`
  .portfolio-cont {
    margin-bottom: 3rem;
    border-radius: 10px;
    max-width: 800px;
    display: flex;
    background-color: ${props =>
      props.theme.mode === 'dark' ? `#0e111b` : 'rgb(250, 250, 250)'};

    .img img {
      height: 100%;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      max-height: 500px;
      @media only screen and (min-width: 768px) {
        border-radius: 0;
      }
    }

    .img img {
      height: 100%;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      max-height: 500px;
      @media only screen and (min-width: 768px) {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }

    .img2 img {
      height: 100%;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      max-height: 500px;
      @media only screen and (min-width: 768px) {
        border-radius: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .portfolio-details {
      height: 100%;
      width: 100%;
      min-width: 300px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        margin: 10px 0 0 0;
        padding: 0;
        font-weight: bold;
        font-size: 26px;
        color: ${props =>
          props.theme.mode === 'dark'
            ? 'var(--lighter-purple)'
            : 'var(--deep-purple)'};
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 0.8rem;
      }

      .dev-yr {
        color: var(--dark-gray);
        font-size: 12px;
        font-weight: 600;
      }

      button {
        margin-bottom: 10px;
      }
    }

    .portfolio-details2 {
      height: 100%;
      width: 100%;
      min-width: 380px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        margin: 10px 0 0 0;
        padding: 0;
        font-weight: bold;
        font-size: 26px;
        color: ${props =>
          props.theme.mode === 'dark'
            ? 'var(--lighter-purple)'
            : 'var(--deep-purple)'};
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 0.8rem;
      }

      .dev-yr {
        color: var(--dark-gray);
        font-size: 12px;
        font-weight: 600;
      }

      button {
        margin-bottom: 10px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .portfolio-cont {
      padding: 0;
      flex-flow: row wrap;
      justify-content: flex-start;

      .portfolio-details,
      .portfolio-details2 {
        padding: 0 15px 15px 15px;
        min-width: 280px;

        h3 {
          font-size: 25px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 0;
        }

        .dev-yr {
          color: var(--dark-gray);
          font-size: 12px;
          font-weight: 600;
        }

        button {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export default PortfolioSection;
