import styled from 'styled-components';

const ArticleSection = styled.article`
  margin: 0 0 2rem 0;
  @media (min-width: 768px) {
    margin: 0 0 3.5rem 0;
    padding: 0 1rem 0 1rem;
  }
  .article-box {
    height: 100%;
    max-width: 400px;
    @media (max-width: 1072px) {
      max-width: 600px;
    }
    margin: 0 auto;
    background-color: ${props =>
      props.theme.mode === 'dark' ? `#0e111b` : 'rgb(250, 250, 250)'};
    border-radius: 10px;

    img {
      width: 100%;
      max-height: 250px;
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    .article-content {
      padding: 0 10px 15px 10px;

      a {
        text-decoration: underline;
        color: ${props =>
          props.theme.mode === 'dark' ? '#919cff' : '#313FB7'};
      }

      p {
        font-size: 0.9rem;
      }

      small {
        background-color: ${props =>
          props.theme.mode === 'dark'
            ? `var(--lang-color-dark)`
            : 'var(--lightest-purple)'};
        color: ${props =>
          props.theme.mode === 'dark'
            ? `var(--lighter-purple)`
            : 'var(--mix-purple)'};
        margin-right: 1rem;
        font-size: 0.7rem;
        padding: 0.2rem 0.4rem;
        border-radius: 5px;
        font-weight: 600;
      }
    }
  }
`;

export default ArticleSection;
