import React from 'react';
import H2 from '../elements/H2';
import PortfolioItem from '../components/PortfolioItem';
import PORTFOLIOS from '../constants/PORTFOLIOS';
import { useState } from 'react';

const Portfolio = () => {
  const [seeProject, setSeeProject] = useState(false);

  return (
    <>
      <H2 className="header2">My Recent Projects</H2>
      {PORTFOLIOS.map(portfolio => (
        <div key={portfolio.title}>
          <PortfolioItem
            portfolio={portfolio}
            seeProject={seeProject}
            setSeeProject={setSeeProject}
          />
        </div>
      ))}
    </>
  );
};

export default Portfolio;
