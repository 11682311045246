import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaPaperPlane } from 'react-icons/fa';
import { FaHome } from 'react-icons/fa';
import '../assets/styles/Contact.scss';
import H2 from '../elements/H2';
import Button from '../components/Button';
import MessageSent from '../assets/images/messagesent.svg';
import StyledP from '../elements/StyledP';
import FormContainer from '../elements/FormContainer';

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const Contact = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  return (
    <>
      <H2 className="header2">Contact me</H2>
      <section id="contact">
        <FormContainer>
          <Formik
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            onSubmit={(values, actions) => {
              fetch('/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: encode({ 'form-name': 'contact', ...values }),
              })
                .then(() => {
                  actions.resetForm();
                  setSuccess(true);
                })
                .catch(() => {
                  setError('Something went wrong, please try again later');
                })
                .finally(() => actions.setSubmitting(false));
            }}
            validate={values => {
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
              const errors = {};
              if (!values.name) {
                errors.name = 'Name required';
              }
              if (!values.email || !emailRegex.test(values.email)) {
                errors.email = 'Valid email required';
              }
              if (!values.message) {
                errors.message = 'Message required';
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <>
                {!success && (
                  <StyledP>
                    If you have an application you are interested in developing,
                    a feature that you need built or a project that needs
                    coding. I’d love to help with it.
                  </StyledP>
                )}
                {!success && !error ? (
                  <Form name="contact" data-netlify={true}>
                    <div className="field">
                      <label className="label" htmlFor="name">
                        Your name
                      </label>
                      <Field name="name" type="text" />
                      <span className="error">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Email
                      </label>
                      <Field name="email" type="email" />
                      <span className="error">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Message
                      </label>
                      <Field
                        name="message"
                        type="message"
                        component="textarea"
                      />
                      <span className="error">
                        <ErrorMessage name="message" />
                      </span>
                    </div>

                    <Button name="Get in Touch" icon={<FaPaperPlane />} />
                  </Form>
                ) : error ? (
                  <StyledP>{error}</StyledP>
                ) : (
                  <Form className="submitted">
                    <StyledP>{success}</StyledP>
                    <img src={MessageSent} alt="message sent" />
                    <Link to="/">
                      <Button
                        name="Return to home"
                        disabled={isSubmitting}
                        icon={<FaHome />}
                      />
                    </Link>
                  </Form>
                )}
              </>
            )}
          </Formik>
        </FormContainer>
      </section>
    </>
  );
};

export default Contact;
