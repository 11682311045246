import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from '../elements/StyledButton';

const Button = ({ name, disabled, icon, onClick, id }) => (
  <StyledButton type="submit" disabled={disabled} onClick={onClick} id={id}>
    <span className="namcon" id={id}>
      {name}
      {icon && (
        <span className="icon" id={id}>
          {icon}
        </span>
      )}
    </span>
  </StyledButton>
);

Button.defaultProps = {
  disabled: false,
  icon: null,
  onClick: null,
  id: null,
};

Button.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.any,
};

export default Button;
