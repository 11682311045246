import React from 'react';
import '../assets/styles/About.scss';
import Red from '../assets/images/red.png';
import Green from '../assets/images/green.png';
import Yellow from '../assets/images/yellow.png';
import H2 from '../elements/H2';
import Section from '../elements/Section';
import aboutMe from '../constants/ABOUT_ME';

const About = () => {
  return (
    <>
      <H2 className="header2">About me</H2>
      <main className="about-main bottom-margin">
        <Section className="about-box shadow">
          <div className="about-container2">
            <img src={Red} alt="Red" />
            <img src={Yellow} alt="Yellow" />
            <img src={Green} alt="Green" />
          </div>
          <div>
            {aboutMe.map(a => (
              <div key={a.name} className="about-code">
                <p className="code">
                  <span>{'>'}</span> Saheed
                  <span>.{a.name}</span>
                </p>
                {typeof a.details === 'string' && (
                  <p>&quot;{a.details}&quot;</p>
                )}
                {typeof a.details !== 'string' && (
                  <div>
                    [
                    <div className="array">
                      {a.details[0]['href']
                        ? a.details.map(detail => (
                            <span key={detail.href}>
                              &quot;{!!detail.main && detail.main}
                              {!!detail.main && detail.href && ', '}
                              {detail.href && (
                                <a
                                  href={detail.href}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {detail.link}
                                </a>
                              )}
                              &quot;,{' '}
                            </span>
                          ))
                        : a.details.map((detail, index) => (
                            <span key={detail}>
                              &quot;{detail}
                              &quot;
                              {a.details.length !== index + 1 && ', '}
                            </span>
                          ))}
                    </div>
                    ]
                  </div>
                )}
              </div>
            ))}
            <div className="about-code">
              <p>
                <span className="blink-span">{'>'}</span>
                <span className="blink">.</span>
              </p>
            </div>
          </div>
        </Section>
      </main>
    </>
  );
};

export default About;
