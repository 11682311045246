import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import storage from 'local-storage-fallback';
import '../assets/styles/Layout.scss';
import NavBar from './NavBar';
import Headerbg from '../assets/images/Headerbg@2x.png';
import HeaderbgDark from '../assets/images/Header bgdark-mode.png';
import HeaderbgMobile from '../assets/images/header-shapes mobile.png';
import HeaderbgMobileDark from '../assets/images/header-shapes mobiledark-mode.png';
import getInitialTheme from '../utils/getinitialTheme';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props =>
      props.theme.mode === 'dark' ? '#171b27' : 'white'};
    color: ${props => (props.theme.mode === 'dark' ? '#EEE' : '#3D3941')};
  }
`;

const Layout = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme);

  useEffect(() => {
    storage.setItem('sotheme', JSON.stringify(theme));
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NavBar mode={theme.mode} setTheme={setTheme} />
      <div className="children">{children}</div>
      <img
        className="headerbg"
        src={theme.mode === 'light' ? Headerbg : HeaderbgDark}
        alt="Headerbg"
      />
      <img
        className="headerbg-mobile"
        src={theme.mode === 'light' ? HeaderbgMobile : HeaderbgMobileDark}
        alt="Headerbg Mobile"
      />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
