import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PopupContainer from '../elements/PopupContainer';
import { FaTimes } from 'react-icons/fa';
import H2 from '../elements/H2';
import Button from './Button';
import { useRef } from 'react';
import PortfolioLanguages from './PortfolioLanguages';

const PortfolioPopup = ({ setSeeProject, portfolio, setPortfolioItem }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPortfolioItem(null);
        setSeeProject(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setPortfolioItem, setSeeProject]);

  const {
    image,
    href,
    title,
    fullDesc,
    year,
    stack,
    languages,
    github,
  } = portfolio;

  return (
    <PopupContainer>
      <div className="popup-inner" ref={popupRef}>
        <div className="popup-header">
          <div>
            <H2>{title}</H2>
            <div className="dev-yr">
              {stack}, {year}
            </div>
          </div>
          <div className="close-icon">
            <FaTimes
              onClick={() => {
                setPortfolioItem(null);
                setSeeProject(false);
              }}
            />
          </div>
        </div>
        <div className="popup-project-details">
          <div className="img">
            <img src={image} alt={title} />
          </div>
          <div className="project-more">
            <p>{fullDesc}</p>
            <div className="lang-btn">
              <PortfolioLanguages languages={languages} />
              <hr />
              {href && (
                <a href={href} target="_blank" rel="noopener noreferrer">
                  <Button name="See live" />
                </a>
              )}
              {github && (
                <a href={github} target="_blank" rel="noopener noreferrer">
                  <Button name="See Source" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupContainer>
  );
};

PortfolioPopup.propTypes = {
  portfolio: PropTypes.object.isRequired,
  setSeeProject: PropTypes.func.isRequired,
  setPortfolioItem: PropTypes.func.isRequired,
};

export default PortfolioPopup;
