import styled from 'styled-components';

const PortfolioLanguagesContainer = styled.div`
  margin: 1.8rem 0;
  span {
    background-color: ${props =>
      props.theme.mode === 'dark'
        ? `var(--lang-color-dark)`
        : 'var(--lightest-purple)'};
    color: ${props =>
      props.theme.mode === 'dark'
        ? `var(--lighter-purple)`
        : 'var(--mix-purple)'};
    margin-right: 1rem;
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
  }
`;

export default PortfolioLanguagesContainer;
