import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaEye } from 'react-icons/fa';
import Button from '../components/Button';
import PortfolioSection from '../elements/PortfoilioSection';
import PortfolioPopup from './PortfolioPopup';
import getPortfolioItem from '../utils/getPortfolioItem';
import useDidUpdate from '../utils/useDidUpdate';
import PortfolioLanguages from './PortfolioLanguages';

const PortfolioItem = ({ portfolio, setSeeProject, seeProject }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [portfolioItem, setPortfolioItem] = useState(null);
  const mobileWidth = '768';

  useDidUpdate(() => {
    setSeeProject(true);
  }, [portfolioItem !== null]);

  const handleSeeProject = e => {
    e.preventDefault();
    setPortfolioItem(getPortfolioItem(e.target.id));
  };

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  const {
    image,
    href,
    title,
    description,
    year,
    index,
    stack,
    languages,
  } = portfolio;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {portfolioItem && seeProject && (
        <PortfolioPopup
          setSeeProject={setSeeProject}
          portfolio={portfolioItem}
          setPortfolioItem={setPortfolioItem}
        />
      )}
      <PortfolioSection>
        {index % 2 === 0 ? (
          <div className="portfolio-cont shadow">
            <div className="img">
              <img src={image} alt={title} />
            </div>
            <div className="portfolio-details">
              <a
                className="main-link"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>{title}</h3>
              </a>
              <div className="dev-yr">
                {stack}, {year}
              </div>
              <p>{description}</p>
              <PortfolioLanguages languages={languages} />
              <Button
                icon={<FaEye />}
                onClick={handleSeeProject}
                name="See Project"
                id={title}
              />
            </div>
          </div>
        ) : width <= mobileWidth ? (
          <div className="portfolio-cont shadow">
            <div className="img">
              <img src={image} alt={title} />
            </div>
            <div className="portfolio-details">
              <a
                className="main-link"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>{title}</h3>
              </a>
              <div className="dev-yr">
                {stack}, {year}
              </div>
              <p>{description}</p>
              <PortfolioLanguages languages={languages} />
              <Button
                icon={<FaEye />}
                onClick={handleSeeProject}
                name="See Project"
                id={title}
              />
            </div>
          </div>
        ) : (
          <div className="portfolio-cont shadow">
            <div className="portfolio-details">
              <a
                className="main-link"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>{title}</h3>
              </a>
              <div className="dev-yr">
                {stack}, {year}
              </div>
              <p>{description}</p>
              <PortfolioLanguages languages={languages} />
              <Button
                icon={<FaEye />}
                onClick={handleSeeProject}
                name="See Project"
                id={title}
              />
            </div>
            <div className="img2">
              <img src={image} alt={title} />
            </div>
          </div>
        )}
      </PortfolioSection>
    </div>
  );
};

PortfolioItem.propTypes = {
  portfolio: PropTypes.object.isRequired,
  setSeeProject: PropTypes.func.isRequired,
  seeProject: PropTypes.bool.isRequired,
};

export default PortfolioItem;
