import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import NotFoundImg from '../assets/images/notfound.svg';
import '../assets/styles/NotFound.scss';
import H2 from '../elements/H2';
import Button from '../components/Button';

const NotFound = () => {
  return (
    <section className="not-found">
      <H2>Sorry, page not found!</H2>
      <img src={NotFoundImg} alt="notfound" />
      <Link to="/">
        <Button name="Return to home" icon={<FaHome />} />
      </Link>
    </section>
  );
};

export default NotFound;
