import styled from 'styled-components';

const NavContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: ${props =>
    props.theme.mode === 'dark' ? `var(--dark-bg)` : 'var(--light-bg)'};
  @media (max-width: 768px) {
    position: relative;
  }
`;

export default NavContainer;
