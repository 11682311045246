import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { FaBookOpen } from 'react-icons/fa';
import { FaAddressCard } from 'react-icons/fa';
import { FaBriefcase } from 'react-icons/fa';
import { FaInfoCircle } from 'react-icons/fa';
import '../assets/styles/NavLinks.scss';
import StyledLink from '../elements/StyledLink';

const NavLinks = ({ mode }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const mobileWidth = '768';
  const [prevScroll, setPrevScroll] = useState(window.pageYOffset);
  const [currentScroll, setCurrentScroll] = useState(window.pageYOffset);
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setCurrentScroll(window.pageYOffset);
      setPrevScroll(currentScroll);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentScroll]);

  const links = [
    { href: '/', name: 'Home' },
    { href: '/articles', name: 'Articles' },
    { href: '/portfolio', name: 'Portfolio' },
    { href: '/about', name: 'About' },
    { href: '/contact', name: 'Contact' },
  ];

  return (
    <div
      className={
        width > mobileWidth
          ? 'nav'
          : `nav-mobile shadow ${prevScroll < currentScroll ? 'hide-nav' : null}`
      }
      style={
        width < mobileWidth
          ? {
              backgroundColor: mode === 'dark' ? '#171b27' : 'white',
            }
          : {}
      }
    >
      {links.map(link => (
        <StyledLink
          key={link.name}
          to={link.href}
          className={`nav-link ${pathname === link.href && 'active'}`}
        >
          <Link to={link.href} rel="noopener noreferrer">
            <div className="icon-container">
              <span className="icon">
                {width < mobileWidth && link.href === '/' && <FaHome />}
                {width < mobileWidth && link.href === '/contact' && (
                  <FaAddressCard />
                )}
                {width < mobileWidth && link.href === '/articles' && (
                  <FaBookOpen />
                )}
                {width < mobileWidth && link.href === '/portfolio' && (
                  <FaBriefcase />
                )}
                {width < mobileWidth && link.href === '/about' && (
                  <FaInfoCircle />
                )}
              </span>
              <span>{link.name}</span>
            </div>
          </Link>
        </StyledLink>
      ))}
    </div>
  );
};

NavLinks.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default NavLinks;
