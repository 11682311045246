import DeviumImg from '../assets/images/devium.png';
import PortfolioImg from '../assets/images/portfolio.png';
import BookourtImg from '../assets/images/bookourt.png';
import WorkeyImg from '../assets/images/workey.png';
import TadaImg from '../assets/images/tada.png';

const PORTFOLIOS = [
  {
    image: TadaImg,
    href: 'https://tada-app.netlify.app/',
    github: 'https://github.com/suretrust/tada-app',
    title: 'Tada',
    description: 'A simple todo application.',
    fullDesc: `This is a simple to-do list web app built with React, Redux and Redux-Saga. This app has no authentication system. Users can list all their todo items. Users can add, edit, and delete a new todo item. Users can mark a todo item complete or incomplete.`,
    year: '2021',
    stack: 'FRONTEND',
    languages: ['es6+', 'react', 'redux', 'redux-saga'],
    index: 5,
  },
  {
    image: WorkeyImg,
    href: 'https://workey.netlify.app/',
    github: 'https://gitlab.com/suretrust/workey',
    title: 'Workey',
    description: 'A web app used to control and record working hours.',
    fullDesc: `Workey is a web app used to control and record the working hours for a company's employees. The app will be used by each employee to manage and keep account of worked hours, Arriving and Exiting hours, as well as Lunch breaks.`,
    year: '2021',
    stack: 'FULL-STACK',
    languages: ['es6', 'react', 'firebase', 'docker'],
    index: 4,
  },
  {
    title: 'Portfolio',
    image: PortfolioImg,
    href: 'https://www.saheedoladele.com',
    description: 'A personalized portfolio design.',
    fullDesc:
      'Portfolio is my personal portfolio designed to showcase my frontend skills. Features include mobile customized navigation that contains all the nav links. Having a hamburger icon when navigation links are not more than six does not seem necessary. It also shows my past projects, articles, and a contact form that uses Netlify forms.',
    year: '2020',
    stack: 'FRONTEND',
    languages: ['es6', 'react', 'styled-components'],
    index: 3,
  },
  {
    image: DeviumImg,
    href: 'https://deviumio.herokuapp.com/',
    github: 'https://github.com/suretrust/devium',
    title: 'Devium',
    description:
      'A social network for software developers from all over the world.',
    fullDesc:
      'Devium is a social media platform for software developers built with rails. It includes features like send friend requests, accept friend requests, delete friend, add post, add comment to post, newsfeed page based on the friends list, login with facebook, and edit settings/profile information.',
    year: '2019',
    stack: 'FULL-STACK',
    languages: ['es6', 'rails', 'react'],
    index: 2,
  },
  {
    image: BookourtImg,
    href: 'https://bookourt.herokuapp.com/',
    github: 'https://github.com/suretrust/bookcourt',
    title: 'Bookourt',
    description:
      'Bookourt is an app that helps you book a tennis court from any part of the world.',
    fullDesc:
      'Bookourt is a mobile first web app built with react and rails. The react front end fetches data from rails REST API backend as well as posts data to it as appropriate. Full authentication is not required and there is no admin panel to add new courts at this time but these are features I plan to implement in the future. It is an app that helps you book available tennis courts from any part of the world. In this app, you can check your future and past bookings as well as search for a court to book by name. Design idea by Vinisha Panjikar on Behance.',
    year: '2019',
    stack: 'FULL-STACK',
    languages: ['scss', 'react', 'rails'],
    index: 1,
  },
];

export default PORTFOLIOS;
