import React from 'react';
import '../assets/styles/Home.scss';
import MediumIcon from '../assets/images/medium.svg';
import TwitterIcon from '../assets/images/twitter.svg';
import LinkedInIcon from '../assets/images/Linkedin icon.svg';
import GitHubIcon from '../assets/images/github.svg';
import AngelListIcon from '../assets/images/angellist.svg';
import H2 from '../elements/H2';

const Home = () => {
  return (
    <main className="main-main">
      <div className="header">
        <H2 className="header-primary">
          Hi, I&apos;m Saheed.
          <br />
          Glad to have you here!
        </H2>
        <p>
          I build pixel-perfect, efficient, and highly intuitive websites and
          applications for next-generation users.
        </p>
        <p className="connect">LET’S CONNECT</p>
        <div className="icons-container">
          <a
            href="http://twitter.com/suretrustng"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={TwitterIcon} alt="twitter icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/saheed-oladele"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={LinkedInIcon} alt="linkedin icon" />
          </a>
          <a
            href="https://medium.com/@saheedoladele"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={MediumIcon} alt="medium icon" />
          </a>
          <a
            href="http://github.com/suretrust"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={GitHubIcon} alt="github icon" />
          </a>
          <a
            href="https://angel.co/u/suretrust"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={AngelListIcon} alt="angellist icon" />
          </a>
        </div>
      </div>
    </main>
  );
};

export default Home;
